import React from 'react'
import ReactDOM from 'react-dom'
import singleSpaReact from 'single-spa-react'

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  loadRootComponent: () =>
    import(/* webpackChunkName: "mf-calculos-root" */ './root.component').then(
      (mod) => mod.default
    ),
  errorBoundary(err, info, props) {
    console.log(err)
    return <div className="module-load-error">Erro ao carregar a aplicação</div>
  }
})

export const { bootstrap, mount, unmount } = lifecycles
